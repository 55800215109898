import React, { useState, useEffect } from "react";
import "../../Assets/css/MegaMenu.css";
import MegaMenu from "../Front/themes/venice/Header/MegaMenu";
const Header = () => {
  
  return <MegaMenu/>
  
};

export default Header;
