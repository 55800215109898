import {
  RiFacebookBoxFill,
  RiTwitterXFill,
  RiInstagramLine,
  RiLinkedinBoxFill,
} from "react-icons/ri";
import { Navigate, Outlet } from "react-router";
import { useSelector } from "react-redux";
//-------------------------------------------------url
export const base_url = "https://dragakovek.com/webapi/api";
export const img_url = "https://dragakovek.com";
export const helmet_url = "https://dragakovek.com";
export const is_paypal = true;
export const is_square = true;
export const Je_welryspelling = "jewelry";

// diamonds page filter (width, depth, table, length, lab)-----------Start*****
export const isWidthFilter = true;
export const isDepthFilter = true;
export const isTableFilter = true;
export const isLengthFilter = true;
export const isLabFilter = true;
// diamonds page filter (width, depth, table, length, lab)------------End******

//select home template
export const Theme = "venice"; // showcase, miami, newyork , london, macau, paris, tokyo, venice

// export const Theme = (() => {
//   if (typeof window === "undefined") {
//     return "default"; 
//   }
//   const hostname = window.location.hostname;
//   if (hostname === "dragakovek.com") {
//     return "showcase";
//   } else if (hostname === "miami.demobw.com") {
//     return "miami";
//   } else if (hostname === "newyork.demobw.com") {
//     return "newyork";
//   } else if (hostname === "london.demobw.com") {
//     return "london";
//   } else if (hostname === "macau.demobw.com") {
//     return "macau";
//   } else if (hostname === "paris.demobw.com") {
//     return "paris";
//   } else if (hostname === "tokyo.demobw.com") {
//     return "tokyo";
//   } else if (hostname === "venice.demobw.com") {
//     return "venice";
//   // } else if (hostname === "rome.demobw.com") {
//   //   return "rome";
//   }
//   return "venice"; 
// })();


export const ImageUrl = 'https://dl2vs6wk2ewna.cloudfront.net/showcase/images/'

// product List View
export const ProductListView = "Vertical";   // Horizontal | Vertical | Both

// product Detail View
export const ProductDetailView = "Slide";   // Slide | Tile | Both

// ------Contact Details Start ------
export const contact_number = {
  // <===---contact Number //
  text: "",
  link: `tel:`,
  chat: "",
};

export const Email_details = {
  // <===---Email_Details  //
  text: "info@dragakovek.com",
  link: `mailto:info@dragakovek.com`,
};

export const Email_detailsSecond = {
  // <===---Second_Email_details  //
  text: "",
  link: `mailto:`,
};

export const Address_details = {
  // <===---Address_details  //
  textLine1: "PO Box 115",
  textLine2: "Mandeville, LA 70471",
  link: "https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d27532.764999010633!2d-90.09402062590488!3d30.3908385588661!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sPO%20Box%20115%20%20Mandeville%2C%20LA%2070471!5e0!3m2!1sen!2sin!4v1740131354548!5m2!1sen!2sin",
};
export const client_name = {
  // <===---client_name  //
  c_name: "Diamond Solutions LLC drága kövek",
};

export const media_details = [
  // <===---media_details  //
  {
    account_alt: "Facbook",
    account_icon: <RiFacebookBoxFill />,
    account_img: `${ImageUrl}static/Social/1.png`,
    account_link: "",
  },
  {
    account_alt: "Twitter",
    account_icon: <RiTwitterXFill />,
    account_img: `${ImageUrl}static/Social/7.png`,
    account_link: "",
  },
  {
    account_alt: "Instgram",
    account_icon: <RiInstagramLine />,
    account_img: `${ImageUrl}static/Social/2.png`,
    account_link: "https://www.instagram.com/dragakovek/",
  },
  {
    account_alt: "Linkedin",
    account_icon: <RiLinkedinBoxFill />,
    account_img: `${ImageUrl}static/Social/3.png`,
    account_link: "",
  },
];

export const InvoiceAddress = () => {
  // <===---Invoice_Address  //
  return (
    <>
      <h4 className="mb-2">PO Box 115</h4>
      <h4 className="mb-0"> Mandeville, LA 70471</h4>
    </>
  );
};

//--------------------------------------------------Api header data
export const postHeader = {
  // 'authorization':`Token e222085edc871220cae528152b73c1317ba2addf`,
  Accept: "application/json",
  "Content-Type": "application/json",
};

export const onTop = () => {
  window.scrollTo(0, 0);
};

export const ProtectedRoutes = () => {
  const user = useSelector((state) => state.persistedReducer.home.userData);
  return user?.isLogin ? <Outlet /> : <Navigate to="/" />;
};
//---------------------------------------------------Ring builder
export const setSteps = (check) => {
  const diamonddata = JSON.parse(localStorage.getItem("bw-diamonddata"))
    ? JSON.parse(localStorage.getItem("bw-diamonddata"))
    : null;
  const settingdata = JSON.parse(localStorage.getItem("bw-settingdata"))
    ? JSON.parse(localStorage.getItem("bw-settingdata"))
    : null;
  const gemstonedata = JSON.parse(localStorage.getItem("bw-gemstonedata"))
    ? JSON.parse(localStorage.getItem("bw-gemstonedata"))
    : null;
  const fancycolordata = JSON.parse(localStorage.getItem("bw-fancycolordata"))
    ? JSON.parse(localStorage.getItem("bw-fancycolordata"))
    : null;
  var arr;
  if (check == 0) {
    if (settingdata == null) {
      arr = [0, 3];
      sessionStorage.setItem("bw-step", JSON.stringify(arr));
    } else if (settingdata != null) {
      arr = [3, 0];
      sessionStorage.setItem("bw-step", JSON.stringify(arr));
    }
  } else if (check == 1) {
    if (settingdata == null) {
      arr = [1, 3];
      sessionStorage.setItem("bw-step", JSON.stringify(arr));
    } else if (settingdata != null) {
      arr = [3, 1];
      sessionStorage.setItem("bw-step", JSON.stringify(arr));
    }
  } else if (check == 2) {
    if (settingdata == null) {
      arr = [2, 3];
      sessionStorage.setItem("bw-step", JSON.stringify(arr));
    } else if (settingdata != null) {
      arr = [3, 2];
      sessionStorage.setItem("bw-step", JSON.stringify(arr));
    }
  } else if (check == 3) {
    if (diamonddata == null) {
      arr = [3, 1];
      sessionStorage.setItem("bw-step", JSON.stringify(arr));
    } else if (diamonddata != null) {
      arr = [1, 3];
      sessionStorage.setItem("bw-step", JSON.stringify(arr));
    }
  } else if (check == 4) {
    if (gemstonedata == null) {
      arr = [3, 2];
      sessionStorage.setItem("bw-step", JSON.stringify(arr));
    } else if (gemstonedata != null) {
      arr = [2, 3];
      sessionStorage.setItem("bw-step", JSON.stringify(arr));
    }
  } else if (check == 5) {
    if (fancycolordata == null) {
      arr = [3, 0];
      sessionStorage.setItem("bw-step", JSON.stringify(arr));
    } else if (fancycolordata != null) {
      arr = [0, 3];
      sessionStorage.setItem("bw-step", JSON.stringify(arr));
    }
  }
};

//---------------------------------------------------stud builder
export const setStudSteps = (check) => {
  const diamonddata = JSON.parse(localStorage.getItem("bw-stud-diamonddata"))
    ? JSON.parse(localStorage.getItem("bw-stud-diamonddata"))
    : null;
  const settingdata = JSON.parse(localStorage.getItem("bw-stud-settingdata"))
    ? JSON.parse(localStorage.getItem("bw-stud-settingdata"))
    : null;
  const gemstonedata = JSON.parse(localStorage.getItem("bw-stud-gemstonedata"))
    ? JSON.parse(localStorage.getItem("bw-stud-gemstonedata"))
    : null;
  const fancycolordata = JSON.parse(
    localStorage.getItem("bw-stud-fancycolordata")
  )
    ? JSON.parse(localStorage.getItem("bw-stud-fancycolordata"))
    : null;
  var arr;
  if (check == 0) {
    if (settingdata == null) {
      arr = [0, 3];
      sessionStorage.setItem("bw-stud-step", JSON.stringify(arr));
    } else if (settingdata != null) {
      arr = [3, 0];
      sessionStorage.setItem("bw-stud-step", JSON.stringify(arr));
    }
  } else if (check == 1) {
    if (settingdata == null) {
      arr = [1, 3];
      sessionStorage.setItem("bw-stud-step", JSON.stringify(arr));
    } else if (settingdata != null) {
      arr = [3, 1];
      sessionStorage.setItem("bw-stud-step", JSON.stringify(arr));
    }
  } else if (check == 2) {
    if (settingdata == null) {
      arr = [2, 3];
      sessionStorage.setItem("bw-stud-step", JSON.stringify(arr));
    } else if (settingdata != null) {
      arr = [3, 2];
      sessionStorage.setItem("bw-stud-step", JSON.stringify(arr));
    }
  } else if (check == 3) {
    if (diamonddata == null) {
      arr = [3, 1];
      sessionStorage.setItem("bw-stud-step", JSON.stringify(arr));
    } else if (diamonddata != null) {
      arr = [1, 3];
      sessionStorage.setItem("bw-stud-step", JSON.stringify(arr));
    }
  } else if (check == 4) {
    if (gemstonedata == null) {
      arr = [3, 2];
      sessionStorage.setItem("bw-stud-step", JSON.stringify(arr));
    } else if (gemstonedata != null) {
      arr = [2, 3];
      sessionStorage.setItem("bw-stud-step", JSON.stringify(arr));
    }
  } else if (check == 5) {
    if (fancycolordata == null) {
      arr = [3, 0];
      sessionStorage.setItem("bw-stud-step", JSON.stringify(arr));
    } else if (fancycolordata != null) {
      arr = [0, 3];
      sessionStorage.setItem("bw-stud-step", JSON.stringify(arr));
    }
  }
};

//------------------- Image error------------------------

export const ErrorImg = (dia_shape, id, type) => {
  if (dia_shape == "Round") {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/round.jpg";
  } else if (dia_shape == "Princess") {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/princess.jpg";
  } else if (dia_shape == "Cushion") {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/cushion.jpg";
  } else if (dia_shape == "Emerald") {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/emerald.jpg";
  } else if (dia_shape == "Oval") {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/oval.jpg";
  } else if (dia_shape == "Radiant") {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/radiant.jpg";
  } else if (dia_shape == "Asscher") {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/asscher.jpg";
  } else if (dia_shape == "Marquise") {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/marquise.jpg";
  } else if (dia_shape == "Heart") {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/heart.jpg";
  } else if (dia_shape == "Pear") {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/pear.jpg";
  } else if (dia_shape == "Gemstone") {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/gemstone.png";
  } else if (dia_shape == "Fancy") {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/fancy_diamond.png";
  } else if (dia_shape === "none") {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/image-not-found.png";
  } else {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/diamond.png";
  }

  if (type == "detail" || type == "Complete") {
    if (type == "Complete") {
      var imgsrc = document
        .getElementsByClassName("image-gallery-thumbnail-image")[1]
        .getAttribute("src");
      var image = new Image();
      image.src = imgsrc;
      image.onerror = () => {
        document.getElementsByClassName(
          "image-gallery-thumbnail-image"
        )[1].src = img;
      };
    } else {
      var imgsrc = document
        .getElementsByClassName("image-gallery-thumbnail")[0]
        .getElementsByTagName("img")[0]
        .getAttribute("src");
      var image = new Image();
      image.src = imgsrc;
      image.onerror = () => {
        document.getElementsByClassName("image-gallery-thumbnail-image")[
          type == "Complete" ? 1 : 0
        ].src = img;
        document
          .getElementsByClassName("image-to-magnify")[0]
          .getElementsByTagName("img")[0].src = img;
      };
      image.onload = () => {
        document
          .getElementsByClassName("image-to-magnify")[0]
          .getElementsByTagName("img")[0].src = imgsrc;
      };
    }
    // image.onload = () => {
    //   document.getElementsByClassName("image-to-magnify")[0].getElementsByTagName("img")[0].src = imgsrc;
    // }
  } else {
    console.log("ImageError sku: ", id);
    document.getElementById(id).src = img;
  }
};

export const formatOrAddCurrencyStrings = (
  currencyString1,
  currencyString2 = null
) => {
  // Helper function to convert currency string to integer in cents
  function toCents(currencyString) {
    return parseFloat(currencyString?.replace(/[^0-9.-]+/g, "")) * 100;
  }

  // If only one string is passed, just format it
  if (currencyString2 === null) {
    const amountInCents = toCents(currencyString1);
    return (amountInCents / 100).toLocaleString("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  }

  // If two strings are passed, add them
  const amountInCents1 = toCents(currencyString1);
  const amountInCents2 = toCents(currencyString2);
  const totalInCents = amountInCents1 + amountInCents2;

  // Convert back to dollars and format with commas and two decimal places
  return (totalInCents / 100).toLocaleString("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
};

export const formatOrAddNumberStrings = (
  numberString1,
  numberString2 = null
) => {
  // Helper function to convert input (string or number) to a float
  function toNumber(input) {
    if (typeof input === "string") {
      return parseFloat(input.replace(/,/g, ""));
    }
    return parseFloat(input); // If it's already a number, return it as-is
  }

  // Helper function to format a number to two decimal places with commas
  function formatNumber(number) {
    return number.toLocaleString("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  }

  // If only one input is passed, format it
  if (numberString2 === null) {
    const number = toNumber(numberString1);
    return formatNumber(number);
  }

  // If two inputs are passed, add them
  const number1 = toNumber(numberString1);
  const number2 = toNumber(numberString2);
  const total = number1 + number2;

  // Format and return the result
  return formatNumber(total);
};
