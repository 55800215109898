import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { client_name, Theme } from "../../Helpers/request";



import VeniceHome from "./themes/venice/VeniceHome";
// import RomeHome from "./themes/rome/component/RomeHome";
/* ========================= */

const Home = () => {
  const metaDetails = useSelector((state) => state.persistedReducer.meta.meta);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{metaDetails?.title}</title>
        <meta name="description" content={metaDetails?.description}></meta>
        <meta name="keywords" content={metaDetails?.keywords}></meta>
        {/*Og Tags */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={metaDetails?.title} />
        <meta property="og:description" content={metaDetails?.description} />
        <meta property="og:url" content={metaDetails?.url} />
        <meta property="og:site_name" content={client_name?.c_name} />
      </Helmet>

      <VeniceHome/>

    </>
  );
};

export default Home;
