import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import BannerSlider from "./Components/Bannerslider";
import Insta from "./Components/insta";
import AOS from "aos";
import "aos/dist/aos.css";
import { useSelector } from "react-redux";
import Testimonial from "./Components/Testimonial";
import DflShopByShape from "./Components/DflShopByShape";
import ExploreCollectionDfl from "./Components/ExploreCollectionDfl";
import DiamondCollectionVideo from "./Components/DiamondCollectionVideo";
import DiamondRing from "./Components/DiamondRing";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const VeniceHome = () => {

  const location = useLocation();

  useEffect(() => {
    // Set background color based on the current route
    if (location.pathname === "/") {
      document.body.style.backgroundColor = "#e2e9e9";
    } else {
      document.body.style.backgroundColor = "white";
    }

    // Cleanup to reset background color on unmount
    return () => {
      document.body.style.backgroundColor = "";
    };
  }, [location.pathname]);


  const metaDetails = useSelector((state) => state.persistedReducer.meta.meta);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      // disable: 'mobile'
    });
  }, []);
  

  return (
    <>
      <div className="dfl_main_bg">
        {/* <BannerSlider /> */}
        <DiamondRing />
        {/* <DiamondCollectionVideo /> */}
        {/* <ExploreCollectionDfl /> */}
        {/* <DflShopByShape /> */}
        {/* <Testimonial /> */}
        <Insta />
      </div>
    </>
  );
};

export default VeniceHome;
