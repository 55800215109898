import React from 'react'
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom';

const VirtualTryOn = () => {
    const history = useHistory();
    const products = [{
        sku: "LGER010",
        slug: "14k-white-gold-lab-grown-diamond-fashion-earrings-earrings-1529",
        img_url: "https://dl2vs6wk2ewna.cloudfront.net/overnight/LGER010/LGER010.jpg"
    },
    {
        sku: "85314-3/8",
        slug: "14k-white-gold-fashion-rings-fashion-rings-163",
        img_url: "https://dl2vs6wk2ewna.cloudfront.net/overnight/85314/85314.jpg",
    },
    {
        sku: "85364",
        slug: "14k-white-gold-round-&-pear-shape-fashion-rings-16",
        img_url: "https://dl2vs6wk2ewna.cloudfront.net/overnight/85364/85364.jpg",
    },
    {
        sku: "70623",
        slug: "14k-white-gold-diamond-crossover-bracelet-bracelets-24",
        img_url: "https://dl2vs6wk2ewna.cloudfront.net/overnight/70623/70623.jpg",
    },
    {
        sku: "70624",
        slug: "14k-white-gold-3-row-diamond-bracelet-bracelets-12",
        img_url: "https://dl2vs6wk2ewna.cloudfront.net/overnight/70624/70624.jpg",
    },
    {
        sku: "70631",
        slug: "14k-white-gold-double-row-bracelets-2",
        img_url: "https://dl2vs6wk2ewna.cloudfront.net/overnight/70631/70631.jpg",
    },
    {
        sku: "60020-7",
        slug: "14k-white-gold-3-prong-graduated-necklace-2",
        img_url: "https://dl2vs6wk2ewna.cloudfront.net/overnight/60020/60020.jpg",
    },
    {
        sku: "60022-5",
        slug: "14k-white-gold-low-base-basket-4-prong-graduating-necklace-252",
        img_url: "https://dl2vs6wk2ewna.cloudfront.net/overnight/60022/60022.jpg",
    },
    {
        sku: "60027-2",
        slug: "14k-white-gold-traditional-graduated-basket-necklace-114",
        img_url: "https://dl2vs6wk2ewna.cloudfront.net/overnight/60027/60027.jpg",
    },
    {
        sku: "32535",
        slug: "14k-white-gold-fashion-pendant-chains-sold-separately-pendants-2795",
        img_url: "https://dl2vs6wk2ewna.cloudfront.net/overnight/32535/32535.jpg",
    },
    {
        sku: "32620",
        slug: "14k-white-gold-flower-pendant-chains-sold-separately-pendants-44",
        img_url: "https://dl2vs6wk2ewna.cloudfront.net/overnight/32620/32620.jpg",
    },
    {
        sku: "32625",
        slug: "14k-white-gold-fashion-pendant-chains-sold-separately-pendants-2",
        img_url: "https://dl2vs6wk2ewna.cloudfront.net/overnight/32625/32625.jpg",
    },
    {
        sku: "50922-E",
        slug: "14k-white-gold-bypass-round-engagement-rings-578203",
        img_url: "https://dl2vs6wk2ewna.cloudfront.net/overnight/50922-E/50922-E.jpg",
    },
    {
        sku: "84118",
        slug: "14k-white-gold-bypass-round-engagement-rings-578203",
        img_url: "https://dl2vs6wk2ewna.cloudfront.net/overnight/84118/84118.jpg",
    },
    {
        sku: "40996-5",
        slug: "14k-white-gold-earrings-halo-emerald-earrings-6827",
        img_url: "https://dl2vs6wk2ewna.cloudfront.net/overnight/40996/40996.jpg",
    }]

    return (
        <div className='virtual-try-on-wrapper'>
            <Container>
                <Row>
                    <Col lg={12}>
                        <h1 className='text-center my-4'><strong>Virtual Try-On</strong></h1>
                    </Col>
                    {products.map((item, idx) => (
                        <Col key={idx} lg={4} className='mb-md-0 mb-4 mt-4'>
                            <div className='item-block' onClick={() => history.push(`/productdetail-2/${item.slug}`)}>
                                <Image src={item?.img_url} alt="" />
                                <h3>SKU - {item.sku}</h3>
                                <Link to={`/productdetail-2/${item.slug}`} className='btn outline-base-btn large'>Virtual Try-On</Link>
                            </div>
                        </Col>
                    ))}



                    {/* <h6 style={{ cursor: "pointer", marginTop: 10 }} onClick={() => {
                        history.push('/productdetail-2/14k-white-gold-lab-grown-diamond-fashion-earrings-earrings-1529')
                    }}  >Product LGER010</h6>
                    <h6 style={{ cursor: "pointer", marginTop: 10 }} onClick={() => {
                        history.push('/productdetail-2/14k-white-gold-fashion-rings-fashion-rings-163')
                    }}  >Product 85314-3/8</h6>
                    <h6 style={{ cursor: "pointer", marginTop: 10 }} onClick={() => {
                        history.push('/productdetail-2/14k-white-gold-round-&-pear-shape-fashion-rings-16')
                    }}  >Product 85364</h6> */}
                </Row>
            </Container>
        </div>
    )
}

export default VirtualTryOn