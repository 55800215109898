import React from "react";
import { NavLink } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { ImageUrl } from '../../../../../Helpers/request';
// import diamondcollection from '../../../../../Assets/images/diamond-collection.webp'
import diamondcollection from '../../../../../Assets/images/diamond-collection.jpg'
// import diamondcollection from '../../../../../Assets/images/DC1.png'
const DiamondRing = () => {
  return (
    <>
      <section className="dfl_diamond_ring_section">
        <Container className="rcs_custome_container">
          <div className=" video-section1">
            <Row>
              <Col lg={6} className="create_diamond_section">
                <h1>D</h1>
                <div className="create_diamond_section_inner">
                  <h6>Create your Own </h6>
                  <h4>Diamond Rings</h4>
                  <p className="title16">
                    {" "}
                    The search for your dream ring begins here. Our ring builder will guide you through the process of finding a beautiful setting and the perfect diamond at a superior value, so you can comfortably start the next chapter of your love story.
                  </p>
                  {/* <p className="title16">Select a setting and choose a diamond to create your own diamond engagement ring. </p> */}
                  <p>
                    <b>Need help?</b> We’re just an email away!
                  </p>
                  <NavLink to="/ringsettings" className="start_btn">
                    Start with a setting
                  </NavLink>
                  <br />
                  <NavLink to="/diamonds" className="start_btn">
                    Start with a diamond
                  </NavLink>
                </div>
              </Col>
              <Col lg={6}>
                <div className="text-center">
                  <LazyLoadImage className="vert-move" alt="Diamond Engagement Ring" src={diamondcollection} />
                  {/* <img
                    src={D_ring}
                    alt="Diamond Engagement Ring"
                    className="vert-move"
                  /> */}
                </div>
              </Col>
            </Row>
          </div> 
        </Container>
      </section>
    </>
  );
};

export default DiamondRing;
