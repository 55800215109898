import React from 'react'
import { FaXTwitter, FaFacebookF, FaPinterestP, FaRegEnvelope, FaWhatsapp, FaLink } from "react-icons/fa6";
import { DropdownButton } from "react-bootstrap";
import { client_name } from '../Helpers/request';
import { PiShareFat } from "react-icons/pi";
import { toast } from "react-toastify";
import {
    FacebookShareButton,
    EmailShareButton,
    PinterestShareButton,
    TwitterShareButton,
    WhatsappShareButton,
} from "react-share";
import copy from "copy-to-clipboard";
export default function ShareIcons() {
    const copyToClipboard = (url) => {
        let copyText = url;
        let isCopy = copy(copyText);
        if (isCopy) {
            toast.success("Copied to Clipboard", { autoClose: 3000 });
        }
    };

    return (
        <div className="rcs_social text-right">

            <DropdownButton
                id={"dropwon-social"}
                title={<><PiShareFat /> Share </>}
                className="bg-transparent"
            >
                <ul>
                    <li>
                        <FacebookShareButton
                            url={String(window.location)}
                            className="Demo__some-network__share-button"
                        >
                            <FaFacebookF /> Facebook
                        </FacebookShareButton>
                    </li>
                    <li>
                        <PinterestShareButton
                            url={String(window.location)}
                            media={String(window.location)}
                            windowWidth={1000}
                            windowHeight={730}
                        >
                            <FaPinterestP /> Pinterest
                        </PinterestShareButton>{" "}
                    </li>
                    <li>
                        <EmailShareButton
                            subject={client_name.c_name}
                            body={String(window.location)}
                        >
                            <FaRegEnvelope /> Email
                        </EmailShareButton>{" "}
                    </li>
                    <li>
                        <TwitterShareButton
                            title={client_name.c_name}
                            url={String(window.location)}
                        >
                            <FaXTwitter /> Twitter
                        </TwitterShareButton>{" "}
                    </li>
                    <li>
                        <WhatsappShareButton
                            title={client_name.c_name}
                            url={String(window.location)}
                        >
                            <FaWhatsapp /> Whatsapp
                        </WhatsappShareButton>{" "}
                    </li>
                    <li
                        onClick={() =>
                            copyToClipboard(String(window.location))
                        }
                    >
                        <FaLink />
                        <span> Copy URL</span>
                    </li>
                </ul>
            </DropdownButton>

        </div>
    )
}
