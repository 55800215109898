import React, { useState, useEffect } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import {ImageUrl} from '../../../../../Helpers/request';
import "swiper/css"; 
import "swiper/css/navigation";
import "swiper/css/pagination";
import SwiperCore, { Autoplay, EffectCoverflow } from "swiper";
SwiperCore.use([Autoplay, EffectCoverflow]);

export default function SwiperCoverflow() {
    var history = useHistory();
    const shopbyshape = (value) => {
        var data1 = {
            shape: value,
        };
        sessionStorage.setItem("bw_d_filter", JSON.stringify(data1));
        history.push("/diamonds");
        window.scrollTo(0, 0);
    };

    return (
        <section className="dfl_shop_diamond_shape_section">
            <div className="title-box2 text-center">
                <h2 className="title24 dark play-font">
                    Shop Diamonds By Shape
                </h2>
            </div>

            <div className="dfl_swiper_coverflow">
                {/* desktop view  */}
                <div className="dfl_shop_by_shape_desktop">
                    <Swiper
                        effect="coverflow"
                        centeredSlides={true}
                        loop={true}
                        coverflowEffect={{
                            rotate: 50,
                            stretch: 0,
                            depth: 50,
                            modifier: 1,
                            slideShadows: false,
                        }}
                        autoplay={{
                            delay: 2000,
                            disableOnInteraction: false
                        }}
                        // modules={[Autoplay]}
                        slidesPerView={6}
                        breakpoints={{
                            // when window width is >= 640px
                            300: {
                                width: 400,
                                slidesPerView: 1,
                            },
                            // when window width is >= 768px

                            768: {
                                slidesPerView: 3,
                            },
                            1200: {
                                slidesPerView: 4,
                            },
                            1300: {
                                slidesPerView: 6,
                            },
                        }}
                    >
                        <SwiperSlide>
                            <button className="btn" onClick={() => shopbyshape("Round")}>
                                <img src={`${ImageUrl}theme/venice/diamond-shape/roundW.webp`} alt="" />
                            </button>
                        </SwiperSlide>

                        <SwiperSlide>
                            <button className="btn" onClick={() => shopbyshape("Princess")}>
                                <img src={`${ImageUrl}theme/venice/diamond-shape/princessW.webp`} alt="" />
                            </button>
                        </SwiperSlide>

                        <SwiperSlide>
                            <button className="btn" onClick={() => shopbyshape("Cushion")}>
                                <img src={`${ImageUrl}theme/venice/diamond-shape/cushionW.webp`} alt="" />
                            </button>
                        </SwiperSlide>

                        <SwiperSlide>
                            <button className="btn" onClick={() => shopbyshape("Emerald")}>
                                <img src={`${ImageUrl}theme/venice/diamond-shape/emeraldW.webp`} alt="" />
                            </button>
                        </SwiperSlide>

                        <SwiperSlide>
                            <button className="btn" onClick={() => shopbyshape("Oval")}>
                                <img src={`${ImageUrl}theme/venice/diamond-shape/ovalW.webp`} alt="" />
                            </button>
                        </SwiperSlide>

                        <SwiperSlide>
                            <button className="btn" onClick={() => shopbyshape("Radiant")}>
                                <img src={`${ImageUrl}theme/venice/diamond-shape/radiantW.webp`} alt="" />
                            </button>
                        </SwiperSlide>

                        <SwiperSlide>
                            <button className="btn" onClick={() => shopbyshape("Asscher")}>
                                <img src={`${ImageUrl}theme/venice/diamond-shape/asscherW.webp`} alt="" />
                            </button>
                        </SwiperSlide>

                        <SwiperSlide>
                            <button className="btn" onClick={() => shopbyshape("Marquise")}>
                                <img src={`${ImageUrl}theme/venice/diamond-shape/marquiseW.webp`} alt="" />
                            </button>
                        </SwiperSlide>

                        <SwiperSlide>
                            <button className="btn" onClick={() => shopbyshape("Heart")}>
                                <img src={`${ImageUrl}theme/venice/diamond-shape/HeartW.webp`} alt="" />
                            </button>
                        </SwiperSlide>

                        <SwiperSlide>
                            <button className="btn" onClick={() => shopbyshape("Pear")}>
                                <img src={`${ImageUrl}theme/venice/diamond-shape/pearW.webp`} alt="" />
                            </button>
                        </SwiperSlide>
                    </Swiper>
                </div>

                {/* mobile view  */}
                <div className="dfl_shop_by_shape_mobile_view">
                    <Swiper
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        loop={true}
                        coverflowEffect={{
                            rotate: 50,
                            stretch: 0,
                            depth: 100,
                            modifier: 1,
                            slideShadows: false,
                        }}
                        // modules={[Autoplay]}
                        breakpoints={{
                            // when window width is >= 640px
                            300: {
                                slidesPerView: 3,
                            },
                            // when window width is >= 768px

                            768: {
                                slidesPerView: 3,
                            },
                        }}
                    >
                        <SwiperSlide>
                            <button
                                className="btn dfl_shopByShap_swiper_box"
                                onClick={() => shopbyshape("Round")}
                            >
                                <div className="">
                                    <img src={`${ImageUrl}diamond-shape/round-diamond.webp`} alt="" />
                                    <h5> Round</h5>
                                </div>
                            </button>
                        </SwiperSlide>

                     

                        <SwiperSlide>
                            <button
                                className="btn dfl_shopByShap_swiper_box"
                                onClick={() => shopbyshape("Princess")}
                            >
                                <div className="">
                                    <img src={`${ImageUrl}diamond-shape/princess-diamond.webp`} alt="" />
                                    <h5> Princess</h5>
                                </div>
                            </button>
                        </SwiperSlide>

                        <SwiperSlide>
                            <button
                                className="btn dfl_shopByShap_swiper_box"
                                onClick={() => shopbyshape("Emerald")}
                            >
                                <div className="">
                                    <img src={`${ImageUrl}diamond-shape/emerald-diamond.webp`} alt="" />
                                    <h5> Emerald</h5>
                                </div>
                            </button>
                        </SwiperSlide>

                        <SwiperSlide>
                            <button
                                className="btn dfl_shopByShap_swiper_box"
                                onClick={() => shopbyshape("Oval")}
                            >
                                <div className="">
                                    <img src={`${ImageUrl}diamond-shape/oval-diamond.webp`} alt="" />
                                    <h5> Oval</h5>
                                </div>
                            </button>
                        </SwiperSlide>

                        <SwiperSlide>
                            <button
                                className="btn dfl_shopByShap_swiper_box"
                                onClick={() => shopbyshape("Radiant")}
                            >
                                <div className="">
                                    <img src={`${ImageUrl}diamond-shape/radiant-diamond.webp`} alt="" />
                                    <h5> Radiant</h5>
                                </div>
                            </button>
                        </SwiperSlide>

                        <SwiperSlide>
                            <button
                                className="btn dfl_shopByShap_swiper_box"
                                onClick={() => shopbyshape("Asscher")}
                            >
                                <div className="">
                                    <img src={`${ImageUrl}diamond-shape/asscher-diamond.webp`} alt="" />
                                    <h5> Asscher</h5>
                                </div>
                            </button>
                        </SwiperSlide>
                        <SwiperSlide>
                            <button
                                className="btn dfl_shopByShap_swiper_box"
                                onClick={() => shopbyshape("Marquise")}
                            >
                                <div className="">
                                    <img src={`${ImageUrl}diamond-shape/marquise-diamond.webp`} alt="" />
                                    <h5> Marquise</h5>
                                </div>
                            </button>
                        </SwiperSlide>

                        <SwiperSlide>
                            <button
                                className="btn dfl_shopByShap_swiper_box"
                                onClick={() => shopbyshape("Heart")}
                            >
                                <div className="">
                                    <img src={`${ImageUrl}diamond-shape/heart-diamond.webp`} alt="" />
                                    <h5> Heart</h5>
                                </div>
                            </button>
                        </SwiperSlide>

                        <SwiperSlide>
                            <button
                                className="btn dfl_shopByShap_swiper_box"
                                onClick={() => shopbyshape("Pear")}
                            >
                                <div className="">
                                    <img src={`${ImageUrl}diamond-shape/pear-diamond.webp`} alt="" />
                                    <h5> Pear</h5>
                                </div>
                            </button>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </section>
    );
}
