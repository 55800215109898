import React, { useEffect, useRef, useState } from "react";
import {
  Col,
  Container,
  Image,
  Nav,
  Row,
  Form,
  Dropdown,
  Accordion,
  Modal,
  Button,
} from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import Login from "../../../../../Components/Login";
import { useHistory } from "react-router-dom";
import { Drawer } from "@mui/material";
import {
  Email_details,
  ImageUrl,
  client_name,
  contact_number,
} from "../../../../../Helpers/request";
import {
  isDesktop,
  isMobile,
  isMobileOnly,
  isTablet,
} from "react-device-detect";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { BsFillChatFill, BsFillTelephoneFill } from "react-icons/bs";
import { IoMdMail, IoMdContact } from "react-icons/io";
import { img_url } from "../../../../../Helpers/request";
import SearchIcon from "@mui/icons-material/Search";
import {
  currencyCode,
  currencyFlag,
  currencyIcon,
  loginDrawer,
} from "../../../../../Reducer/homeReducer";
import logo from '../../../../../Assets/images/logo.png'

const MegaMenu = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [submenu, setSubmenu] = useState([]);
  const [show, setShow] = useState(false);
  const [search_show, setSearch_show] = useState(false);
  const [topHeader, setTopHeader] = useState(true);
  const [left, setLeft] = useState(false);
  const [ringBuiliderClear, setRingBuiliderClear] = useState(false);
  const [hover, setHover] = useState(0);
  const [search_text, setSearch_text] = useState("");
  const menudata = useSelector((state) => state.persistedReducer.home.menu);
  const currencycode = useSelector(
    (state) => state.persistedReducer.home.currencycode
  );
  const currencyflag = useSelector(
    (state) => state.persistedReducer.home.currencyflag
  );
  const currencydata = useSelector(
    (state) => state.persistedReducer.home.currency
  );
  const user = useSelector((state) => state.persistedReducer.home.userData);
  useEffect(() => {
    document.body.className = window.location.pathname;
    return () => {
      document.body.className = "";
    };
  }, [window.location.pathname]);

  const [
    isScrollValueMoreThanHeaderHeight,
    setIsScrollValueMoreThanHeaderHeight,
  ] = useState(false);

  const cartlength = useSelector(
    (state) => state.persistedReducer.cart.cartlength
  );
  const wishlistlength = useSelector(
    (state) => state.persistedReducer.wish.whishlistlength
  );
  const toggleDrawer = (open) => {
    setLeft(open);
  };

  useEffect(() => {
    // googleTranslateElementInit()
    const handleScroll = () => {
      // console.log(window.scrollY);
      setIsScrollValueMoreThanHeaderHeight(window.scrollY > 20);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        includedLanguages: "en,es,fr,de,iw,ja,zh-CN,sv,tr,ar,hi",
        gaTrack: true,
      },
      "google_translate_element"
    );
  };
  // const history = useHistory();
  // bw-stud-settingdata
  // useEffect(() => {
  //   if (window.location.pathname === "/complete-diamond-stud" && !JSON.parse(localStorage.getItem("bw-stud-settingdata")) && !JSON.parse(localStorage.getItem("bw-stud-diamonddata"))) {
  //     history.push('/')
  //   }
  // }, [window.location.pathname])

  useEffect(() => {
    if (
      ringBuiliderClear &&
      JSON.parse(localStorage.getItem("bw-settingdata"))
    ) {
      if (window.location.pathname.includes("/gemstones")) {
        localStorage.removeItem("bw-settingdata");
        sessionStorage.setItem("bw-step", JSON.stringify([2, 3]));
        setRingBuiliderClear(false);
      } else if (window.location.pathname.includes("/fancy-color-diamond")) {
        localStorage.removeItem("bw-settingdata");
        sessionStorage.setItem("bw-step", JSON.stringify([0, 3]));
        setRingBuiliderClear(false);
      } else {
        localStorage.removeItem("bw-settingdata");
        sessionStorage.setItem("bw-step", JSON.stringify([1, 3]));
        setRingBuiliderClear(false);
      }
    }
  }, [ringBuiliderClear]);
  useEffect(() => {
    //  if (props.location.pathname !== "/stud-settings") {
    sessionStorage.removeItem("bw_s_filter");
    // }
  }, [window.location.pathname]);

  const currencyHandle = async (code, img, icon) => {
    await dispatch(currencyIcon(icon));
    await dispatch(currencyCode(code));
    await dispatch(currencyFlag(img));
    localStorage.removeItem("bw-fancycolordata");
    localStorage.removeItem("bw-gemstonedata");
    localStorage.removeItem("bw-diamonddata");
    localStorage.removeItem("bw-settingdata");
    sessionStorage.removeItem("bw-listfilter");
    sessionStorage.removeItem("bw_f_filter");
    sessionStorage.removeItem("bw_s_filter");
    sessionStorage.removeItem("bw_d_filter");
    sessionStorage.removeItem("bw_g_filter");
    sessionStorage.removeItem("bw-list-category");
    if (window.location.pathname == "/complete-diamond-ring") {
      history.push("/diamonds");
    } else if (window.location.pathname == "/complete-gemstone-ring") {
      history.push("/gemstones");
    } else if (window.location.pathname == "/complete-fancycolor-ring") {
      history.push("/fancy-color-diamond");
    }
    window.location.reload();
  };
  const [screenWidth, setScreenWidth] = useState(window.screen.width);
  const resizeScreen = () => {
    setScreenWidth(window.innerWidth);
  };
  useEffect(() => {
    resizeScreen();
    window.addEventListener("resize", resizeScreen);
    return () => {
      window.removeEventListener("resize", resizeScreen);
    };
  });

  const divRef = useRef(null);
  const [divHeight, setDivHeight] = useState(0);

  useEffect(() => {
    if (menudata.length > 0 && divRef.current) {
      setDivHeight(divRef.current.offsetHeight);
    }
  }, [menudata]);

  return (
    <>
      <div className="rcs_header_seaction" id="rcs_header_seaction">
        {topHeader ? (
          <div className="rcs_top_header">
            <Container className="custom-container_top">
              <div className="rcs_top_head_content">
                <div className="rcs_top_head_content">
                  <Row className="w-100 m-auto align-items-center">
                    <Col lg={3} xs={6} className="p-0">
                      <div className="dfl_header_logo">
                        <Nav className="m-auto rcs_mobile_header_menu">
                          <Image
                            onClick={() => toggleDrawer(true)}
                            src={`${ImageUrl}menu.png`}
                            alt="menu icon"
                            width="30px"
                          ></Image>
                        </Nav>
                        <Image width={200}
                          className="dfl_logo_top_m"
                          style={{ cursor: "pointer" }}
                          onClick={() => history.push("/")}
                          src={logo}
                          alt="Belgium Webnet"
                        ></Image>
                      </div>
                    </Col>
                   
                      <Col xs={6}  lg={9} className="pl-0">
                     
                        <div className="dfl_new_header_top_row">
                          <div>
                            <div className="dfl_top_right_number">
                              <a
                                href={`mailto:${Email_details.link}`}
                                className="text-uppercase"
                              >
                                <span className="mx-2">Customer Service </span>
                                <small>{Email_details.text}</small>
                              </a>
                            </div>
                          </div>
                          <div>
                            <div className="rcs_search_bar dfl_top_search">
                              <Form
                                onSubmit={(e) => {
                                  e.preventDefault();
                                  history.push("/search/" + search_text);
                                  window.scrollTo(0, 0);
                                  setSearch_text("");
                                  setSearch_show(false);
                                }}
                              >
                                <Form.Control
                                  type="text"
                                  placeholder="Search"
                                  value={search_text}
                                  onChange={(e) =>
                                    setSearch_text(e.target.value)
                                  }
                                />
                                <SearchIcon
                                  onClick={(e) => {
                                    history.push("/search/" + search_text);
                                    setSearch_text("");
                                    setSearch_show(false);
                                  }}
                                />
                              </Form>
                            </div>
                          </div>
                        </div>
                     
                      <div className="dfl_top_right_cont_main">
                            <div className="rcs_search_bar dfl_top_search">
                              <div className="rcs_right_side dfl_right_icn_content ">
                                <ul>
                                  <li style={{cursor:'pointer'}}>
                                    <Login
                                      Img={`${ImageUrl}user1.png`}
                                      Text={isMobile || isTablet ? '' : "Login"}
                                    />
                                  </li>
                                  <li
                                    className=""
                                    onClick={() =>
                                      user?.isLogin
                                        ? history.push("/account/wishlist")
                                        : history.push("/wishlist")
                                    }
                                  >
                                    
                                    <img
                                      className="rcs_wishlist_icon"
                                      src={`${ImageUrl}heart1.png`}
                                      alt={"Heart"}
                                    />
                                    <span
                                      className="dfl_right_menu_text"
                                      style={{ cursor: "pointer" }}
                                    >
                                      
                                      Wishlist
                                    </span>
                                    {wishlistlength && wishlistlength != "0" ? (
                                      <span className="rcs_wish_badge">
                                        ({wishlistlength})
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </li>
                                  <li
                                    className=""
                                    onClick={() => {
                                      history.push("/cart");
                                    }}
                                  >
                                    <img
                                      className="rcs_wishlist_icon"
                                      src={`${ImageUrl}bag1.png`}
                                      alt={"cart"}
                                    />
                                    <span
                                      className="dfl_right_menu_text"
                                      style={{ cursor: "pointer" }}
                                    >
                                      
                                      My Cart
                                    </span>
                                    {cartlength && cartlength != "0" ? (
                                      <span className="rcs_cart_badge">
                                        ({cartlength})
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                     
                    
                      </Col>
                   
                  </Row>
                </div>
              </div>
            </Container>
          </div>
        ) : (
          ""
        )}
        {/* Top Header Section */}
        <div
          className={
            isScrollValueMoreThanHeaderHeight
              ? "rcs_logo_head rcs_sticky-top sticky-top"
              : "rcs_logo_head"
          }
        >
          {/* Header Section */}
          <div className="rcs_header">
            <Container className="custom-container_top">
              <Row className="w-100 m-auto align-items-center .justify-content-center">
                <Col
                  sm={12}
                  md={12}
                  lg={3}
                  xl={3}
                  xs={12}
                  className="rcs_large_Tab dfl_sticky_logo"
                >
                  <div className="dfl_header_logo_top dfl_mob">
                    <div className="dfl_header_fixed_logo">
                      <Image width={200} className="py-xl-2"
                        style={{ cursor: "pointer" }}
                        onClick={() => history.push("/")}
                        src={logo}
                        alt="Belgium Webnet"
                      ></Image>
                    </div>
                    <div className="m-auto rcs_mobile_header_menu">
                      <Image
                        onClick={() => toggleDrawer(true)}
                        src={`${ImageUrl}menu.png`}
                        alt="menu icon"
                        width="30px"
                      ></Image>
                    </div>
                  </div>

                  {/* fixed header wishlist search and login icon  */}
                  <div className="rcs_right_side dfl_right_icn_content dfl_mobile_icn">
                    <ul>
                      {/* <li
                        className="d-none d-lg-block d-md-block dfl_tab_search "
                        onClickAway={() => setSearch_show(false)}
                      >
                        <img
                          onClick={() => setSearch_show(!search_show)}
                          className="rcs_wishlist_icon"
                          src={`${ImageUrl}search1.png`}
                          alt={"search"}
                        />
                        <Modal
                          className={
                            isScrollValueMoreThanHeaderHeight
                              ? "rcs_search_modal12"
                              : "rcs_search_modal"
                          }
                          show={search_show}
                          onHide={() => setSearch_show(false)}
                        >
                          <div className="rcs_search_bar">
                            <Form
                              onSubmit={(e) => {
                                e.preventDefault();
                                history.push("/search/" + search_text);
                                window.scrollTo(0, 0);
                                setSearch_text("");
                                setSearch_show(false);
                              }}
                            >
                              <SearchIcon
                                onClick={(e) => {
                                  history.push("/search/" + search_text);
                                  setSearch_text("");
                                  setSearch_show(false);
                                }}
                              />
                              <Form.Control
                                type="text"
                                placeholder="Search for.."
                                value={search_text}
                                onChange={(e) => setSearch_text(e.target.value)}
                              />
                              <AiOutlineClose
                                onClick={() => setSearch_show(!search_show)}
                              />
                            </Form>
                          </div>
                        </Modal>
                      </li> */}
                      <li>
                      <Login
                                      Img={`${ImageUrl}user1.png`}
                                      Text={isMobile || isTablet ? '' : "Login"}
                                    />
                      </li>
                      <li
                        className=""
                        onClick={() =>
                          user?.isLogin
                            ? history.push("/account/wishlist")
                            : history.push("/wishlist")
                        }
                      >
                        
                        <img
                          className="rcs_wishlist_icon"
                          src={`${ImageUrl}heart1.png`}
                          alt={`wishlist`}
                        />
                        <span
                          className="dfl_right_menu_text"
                          style={{ cursor: "pointer" }}
                        >
                          
                          Wishlist
                        </span>
                        {wishlistlength && wishlistlength != "0" ? (
                          <span className="rcs_wish_badge">
                            {wishlistlength}
                          </span>
                        ) : (
                          ""
                        )}
                      </li>
                      <li
                        className=""
                        onClick={() => {
                          history.push("/cart");
                        }}
                      >
                        <img
                          className="rcs_wishlist_icon"
                          src={`${ImageUrl}bag1.png`}
                          alt={"Cart1"}
                        />
                        <span
                          className="dfl_right_menu_text"
                          style={{ cursor: "pointer" }}
                        >
                          
                          Cart
                        </span>
                        {cartlength && cartlength != "0" ? (
                          <span className="rcs_cart_badge">{'('}{cartlength}{')'}</span>
                        ) : (
                          ""
                        )}
                      </li>
                    </ul>
                  </div>
                  {/*fixed header wishlist search and login icon  */}
                </Col>

                <Col
                  sm={12}
                  md={12}
                  lg={9}
                  xs={12}
                  className="rcs_large_Tab1 p-0"
                  style={{ position: "unset" }}
                >
                  {/* Menu Section */}
                  <div className="rcs_menu">
                    <nav className="p-0" id="rcs_menu_header_top">
                      <ul className="rcs_header_menu">
                        {menudata?.map((value) => (
                          <li
                            className="rcs_top_header_menu"
                            onMouseEnter={() => {
                              setHover(true);
                              setSubmenu(value);
                              console.log("value", value);
                            }}
                            onMouseLeave={() => setHover(false)}
                          >
                            <h6 className="1st">
                              <NavLink to={value?.slug}>{value?.name}</NavLink>
                            </h6>
                            {value?.design === 5 ? (
                              <ul
                                className="rcs_top_header_menu_dropdown"
                                onMouseEnter={() => setHover(true)}
                                onMouseLeave={() => setHover(false)}
                              >
                                {submenu?.submenu?.map((val) => (
                                  <li className="dir rcs_top_header_menu1">
                                    <span>
                                      <NavLink to={val?.slug}>
                                        {val.name}
                                      </NavLink>
                                    </span>
                                  </li>
                                ))}
                              </ul>
                            ) : (
                              ""
                            )}
                          </li>
                        ))}
                      </ul>
                    </nav>
                  </div>
                  {/* menu Section */}
                </Col>
              </Row>
            </Container>
          </div>
          {/* Header Section */}

          {/*mega menu */}
          <div className="menu_item_main position-relative">
            {submenu?.design !== 5 ? (
              <div
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                className={
                  hover && submenu?.submenu?.length
                    ? "rcs_top_header_menu_dropdown mega_menu_content_main visible bw-" +
                      submenu?.name?.replaceAll(" ", "-")
                    : "rcs_top_header_menu_dropdown mega_menu_content_main invisible"
                }
              >
                <div className="rcs_megaMenu d-block">
                  <div className="rcs_megaMenu_inner_top">
                    <div className="rcs_megaMenu_inner">
                      <Container fluid className="p-0">
                        <Row className="w-100 m-auto  justify-content-center">
                          {submenu?.submenu?.map((val, index) => (
                            <>
                              <Col lg={val?.col} xl={val?.col} className="pl-0">
                                {val?.submenu?.map((val1) =>
                                  val1?.design == "1" ? (
                                    <div
                                      className={
                                        "rcs_diamonds_content mj_daimond_d_font bw-" +
                                        val1?.name?.replaceAll(" ", "-")
                                      }
                                    >
                                      <div className="mega-list-cat">
                                        <h2 className="title14 font-bold text-uppercase mtb">
                                          {val1?.name}
                                        </h2>
                                        <ul>
                                          {val1?.submenu?.map((val2) => (
                                            <li>
                                              {val2?.design == 4 ? (
                                                <Link
                                                  to="#"
                                                  onClick={() =>
                                                    window.open(
                                                      img_url + val2?.slug,
                                                      "_target"
                                                    )
                                                  }
                                                >
                                                  {val2?.img_url?.length ? (
                                                    <img
                                                      src={val2?.img_url}
                                                      className="img-size"
                                                      alt={val2?.alt_tag}
                                                    />
                                                  ) : (
                                                    ""
                                                  )}
                                                  {val2?.name}
                                                </Link>
                                              ) : (
                                                <Link to={val2?.slug}>
                                                  {val2?.img_url?.length ? (
                                                    <img
                                                      src={val2?.img_url}
                                                      className="img-size"
                                                      alt={val2?.alt_tag}
                                                    />
                                                  ) : (
                                                    ""
                                                  )}
                                                  {val2?.name}
                                                </Link>
                                              )}
                                            </li>
                                          ))}
                                        </ul>
                                      </div>
                                    </div>
                                  ) : val1?.design == "2" ? (
                                    <div
                                      className={
                                        "rcs_diamonds_content dfL-border_rel bw-" +
                                        val1?.name?.replaceAll(" ", "-")
                                      }
                                    >
                                      <h2> {val1?.name} </h2>
                                      <div className="m-auto w-100 row">
                                        {val1?.submenu?.map((val2) => (
                                          <div className="cust_padd_diamonds_shape col-lg-6">
                                            <div className="rcs_diamonds_content_inner">
                                              <Link to={val2?.slug}
                                              onClick={() =>{ setHover(0); 
                                                if(val2?.slug.includes("shape"))
                                                   {
                                                    setRingBuiliderClear(true);
                                                  }
                                                   }}
                                              >
                                                {val2?.img_url?.length ? (
                                                  <img
                                                    src={val2?.img_url}
                                                    alt={val2?.alt_tag}
                                                  />
                                                ) : (
                                                  ""
                                                )}
                                                <p>{val2?.name}</p>
                                              </Link>
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  ) : val1?.design == "3" ? (
                                    <div className="rcs_diamonds_content">
                                      <Link to={val1?.slug}>
                                        {val1?.img_url?.length ? (
                                          <img
                                            src={val1?.img_url}
                                            alt={val1?.alt_tag}
                                            className="img-fluid dfl_ddiamond_img"
                                            style={{
                                              height: "100%",
                                              objectFit: "cover",
                                            }}
                                          />
                                        ) : (
                                          ""
                                        )}
                                      </Link>
                                    </div>
                                  ) : (
                                    ""
                                  )
                                )}
                                {index < 2 ? (
                                  <div className="dfl_menu_border"> </div>
                                ) : (
                                  ""
                                )}
                              </Col>
                            </>
                          ))}
                        </Row>
                      </Container>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {hover && submenu?.submenu?.length ? (
              <div className="hover_overlayer"></div>
            ) : (
              ""
            )}
          </div>

          {/* menu Section */}
        </div>
        {/* Mobile Menu Section */}
        <div className="rcs_menu_mobile d-none">
          <Drawer anchor="left" open={left} onClose={() => toggleDrawer(false)}>
            <div
              role="presentation"
              // onKeyDown={() => toggleDrawer(false)}
            >
              <div
                className={
                  show === true ? "mobile-menu" : "mobile-menu nav-open"
                }
              >
                {/* <div className="mobile-auth-with-currency d-flex align-items-center justify-content-between p-2">
                  <div className="mobile-auth d-flex align-items-center justify-content-start">
                     <span onClick={() => dispatch(loginDrawer(true))}>Login / Signup</span>
                  </div>

                  <div className="">
                    <Dropdown
                      className={
                        "d-inline rcs_dropdown_lang " + "showcase_new_lang"
                      }
                    >
                      <Dropdown.Toggle id="dropdown-autoclose-true">
                        <Image width={30} src={currencyflag} alt={currencycode}></Image> &nbsp;
                        <span>{currencycode}</span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {currencydata?.map((val) => (
                          <Dropdown.Item
                            onClick={() => {
                              currencyHandle(
                                val.currency_code,
                                val.country_flag,
                                val.currency_icon
                              );
                            }}
                            href="#"
                          >
                            <Image width={30}
                              src={val.country_flag}
                              alt={val.currency_code}
                            ></Image> &nbsp;
                            <span> {val.currency_code}</span>
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div> */}
                <div className="inner-mobile-menu">
                  <div className="rcs_search_bar mobileSearchBox px-2 mt-2">
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                        history.push("/search/" + search_text);
                        window.scrollTo(0, 0);
                        setSearch_text("");

                        toggleDrawer(false);
                      }}
                    >
                      {/* <SearchIcon
                      onClick={(e) => {
                        history.push("/search/" + search_text);
                        setSearch_text("");
                        
                      }}
                    /> */}
                      <svg
                        onClick={(e) => {
                          history.push("/search/" + search_text);
                          setSearch_text("");
                        }}
                        width="16"
                        height="16"
                        style={{ opacity: "0.3" }}
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M19.7112 18.3373L15.2347 13.8554C16.3899 12.3855 17.0878 10.5542 17.0878 8.55422C17.0878 3.83133 13.2611 0 8.54392 0C3.82671 0 0 3.83133 0 8.55422C0 13.2771 3.82671 17.1084 8.54392 17.1084C10.5656 17.1084 12.4188 16.4096 13.8628 15.2289L18.3393 19.7108C18.5319 19.9036 18.7726 20 19.0132 20C19.2539 20 19.4946 19.9036 19.7112 19.7108C20.0963 19.3253 20.0963 18.7229 19.7112 18.3373ZM1.92539 8.55422C1.92539 4.89157 4.88568 1.92771 8.54392 1.92771C12.2022 1.92771 15.1625 4.89157 15.1625 8.55422C15.1625 12.2169 12.2022 15.1807 8.54392 15.1807C4.88568 15.1807 1.92539 12.2169 1.92539 8.55422Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                      <Form.Control
                        type="text"
                        placeholder="Search for.."
                        value={search_text}
                        onChange={(e) => setSearch_text(e.target.value)}
                      />
                      {/* <AiOutlineClose
                      onClick={() => setSearch_show(!search_show)}
                    /> */}
                    </Form>
                  </div>

                  <Accordion defaultActiveKey="0">
                    {/* <Accordion.Item eventKey="0">
                            <Accordion.Header><Link to="#"><span className='auth'><img src={User} alt="" /></span> LOGIN/SIGNUP</Link></Accordion.Header>
                        </Accordion.Item> */}
                    {menudata?.map((val, index) => (
                      <Accordion.Item eventKey={index}>
                        <Accordion.Header
                          className={`${
                            val.name === "Custom Design" ||
                            val.name === "Watches"
                              ? "no_button"
                              : ""
                          }`}
                        >
                          <Link
                            to={val.slug}
                            onClick={() => toggleDrawer(false)}
                          >
                            {val.name}
                          </Link>
                        </Accordion.Header>
                        <Accordion.Body>
                          {val?.submenu?.map((val1) =>
                            val1?.submenu?.map((val2) =>
                              val2?.design == 3 ? (
                                <img
                                  className="w-100 mt-2"
                                  onClick={() => history.push(val2?.slug)}
                                  src={val2?.img_url}
                                  alt={val2?.alt_tag}
                                />
                              ) : (
                                <>
                                  <h2 className="title">{val2?.name}</h2>
                                  <ul
                                    className={
                                      val2?.design == 2
                                        ? "two-col-list"
                                        : "single-col-list"
                                    }
                                  >
                                    {val2?.submenu?.map((val3) => (
                                      <li>
                                        <Link
                                          onClick={() => {
                                            toggleDrawer(false);
                                            setRingBuiliderClear(true);
                                          }}
                                          to={val3.slug}
                                        >
                                          {val3?.img_url ? (
                                            <img
                                              src={val3?.img_url}
                                              alt={val3?.alt_tag}
                                            />
                                          ) : (
                                            ""
                                          )}
                                          {val3?.name}
                                        </Link>
                                      </li>
                                    ))}
                                  </ul>
                                </>
                              )
                            )
                          )}
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}

                    <Accordion.Item eventKey="7" className="contactDetail">
                      <Accordion.Header>Contact us</Accordion.Header>
                      <div className="contact_detail">
                        <ul>
                          {/* <li>
                            <a
                              onClick={() => setShow(false)}
                              target="_blank"
                            href="https://api.whatsapp.com/send?phone=+6469929024 &amp;text=Hi Arman Jwelers ! I have a few queries. Could you please help me out?"

                            href={"https://api.whatsapp.com/send?phone=" + contact_number.chat +  " &amp;text=Hi " + client_name.c_name + "! I have a few queries. Could you please help me out?"}
                            >
                              <span>
                                <BsFillChatFill />
                              </span>
                              Chat
                            </a>
                          </li> */}
                          <li>
                            <a
                              onClick={() => {
                                setShow(false);
                                window.location.href = contact_number.link;
                              }}
                              href="#"
                            >
                              <span>
                                <BsFillTelephoneFill />
                              </span>
                              Call
                            </a>
                          </li>
                          <li>
                            <a
                              onClick={(e) => {
                                setShow(false);
                                window.location.href = Email_details.link;
                              }}
                            >
                              <span>
                                <IoMdMail />
                              </span>
                              Email
                            </a>
                          </li>
                          <li onClick={() => toggleDrawer(false)}>
                            <Link onClick={() => setShow(false)} to="/contact">
                              <span>
                                <IoMdContact />
                              </span>
                              Contact
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
            </div>
          </Drawer>
        </div>
        {/* Mobile Menu Section */}
      </div>
    </>
  );
};
export default React.memo(MegaMenu);
